import { useParams } from "react-router-dom";
import ControlSize from "../controls/ControlSize";
import Label from "../controls/Label";
import Cell, { placeholderCellData } from "../data/Cell";
import { usePlaceData } from "../data/PlaceDataProvider";
import { LockClosedIcon } from "@heroicons/react/solid";

function formatModel(model?: string): string | undefined {
  if (!model) {
    return undefined;
  }

  const cellAlphaPrefix = "C01_";
  if (model.startsWith(cellAlphaPrefix)) {
    const suffix = model.replace(cellAlphaPrefix, "");

    if (suffix === "MP1") {
      return "Cell Alpha";
    } else {
      return `Cell Alpha (${suffix})`;
    }
  }

  return model;
}

function formatWiFiChannel(channel?: number): string | undefined {
  if (!channel) {
    return undefined;
  }

  if (channel <= 14) {
    return "2.4 GHz";
  }

  return "5 GHz";
}

function formatOSVersion(version?: string): string | undefined {
  if (version) {
    return version.replace(/^(SyngOS|Troubadour)-/, "");
  }

  return undefined;
}

function formatRelativeDate(date?: Date): string | undefined {
  if (!date) {
    return undefined;
  }

  const elapsed = date.valueOf() - Date.now();
  const formatter = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  const units: { [P in Intl.RelativeTimeFormatUnit]?: number } = {
    year: 1000 * 60 * 60 * 24 * 365,
    month: (1000 * 60 * 60 * 24 * 365) / 12,
    day: 1000 * 60 * 60 * 24,
    hour: 1000 * 60 * 60,
    minute: 1000 * 60,
  };

  var name: Intl.RelativeTimeFormatUnit;
  for (name in units) {
    const value = units[name];
    if (value && Math.abs(elapsed) > value) {
      return formatter.format(Math.round(elapsed / value), name);
    }
  }

  return formatter.format(Math.round(elapsed / 1000), "second");
}

function formatRSSI(rssi?: number): string | undefined {
  if (rssi) {
    return `${rssi} dBm`;
  }

  return undefined;
}

function formatWiFiStrength(rssi?: number): string | undefined {
  if (rssi) {
    return String(Math.min(100, (rssi + 100) * 2)) + "%";
  }

  return undefined;
}

function formatBoolean(value?: boolean): string | undefined {
  if (value) {
    return value ? "Yes" : "No";
  }

  return undefined;
}

function formatHeightLayer(value?: number): string | undefined {
  if (value) {
    return String(value);
  }

  return undefined;
}

const TextProperty = ({ value }: { value?: string }) => {
  const isMissing = value === undefined;
  return (
    <Label secondary={isMissing} disabled={isMissing} title={value ?? "—"} />
  );
};

type RowProps = {
  title: string;
  value?: string;
  children?: React.ReactNode;
};

const Row = (props: RowProps) => {
  return (
    <div className="flex justify-between py-3 text-sm font-medium">
      <dt>
        <Label secondary loading={undefined} title={props.title} />
      </dt>
      <dd className="flex-grow text-right">
        {props.children || <TextProperty value={props.value} />}
      </dd>
    </div>
  );
};

type OTA = NonNullable<Cell["ota"]>;

function effectiveProgress(ota: OTA): number | undefined {
  switch (ota.status) {
    case "downloading":
      return ota.progress / 2.0;
    case "installing":
      return ota.progress / 2.0 + 50;
    default:
      return undefined;
  }
}

function statusText(ota: OTA, isOnline: boolean): string {
  switch (ota.status) {
    case "already_installed":
      return "Up to date";
    case "downloading":
      return `Downloading: ${ota.progress}%`;
    case "installing":
      return `Installing: ${ota.progress}%`;
    case "rebooting":
      return isOnline ? "Ready to reboot" : "Rebooting…";
    case "success":
      return "Installed Successfully";
    case "failure":
      return "Failed";
    case "aborted":
      return "Aborted";
  }
}

const ProgressBar = ({ progress }: { progress: number }) => {
  const style = {
    width: `${progress}%`,
  };
  return (
    <div className="relative ml-8 h-3 max-w-full rounded-full border-2 border-stone-100 bg-stone-100 ring-1 ring-stone-500 dark:border-neutral-700 dark:bg-neutral-700 dark:ring-neutral-400">
      <div
        className="ease absolute top-0 left-0 h-2 min-w-[0.5rem] rounded-full bg-stone-500 transition-all duration-200 dark:bg-neutral-400"
        style={style}
      />
    </div>
  );
};

const OTAIndicator = ({ ota, isOnline }: { ota: OTA; isOnline: boolean }) => {
  const progress = effectiveProgress(ota);

  if (progress !== undefined) {
    return (
      <div title={statusText(ota, isOnline)}>
        <ProgressBar progress={progress} />
      </div>
    );
  }

  return (
    <>
      <Label title={statusText(ota, isOnline)} />
    </>
  );
};

const OTARow = (props: { title: string; value?: OTA; isOnline: boolean }) => {
  return (
    <Row title={props.title}>
      {props.value && (
        <OTAIndicator ota={props.value} isOnline={props.isOnline} />
      )}
    </Row>
  );
};

const RoleIndicator = (props: { role: string; isPinned: Boolean }) => {
  return (
    <span
      className="flex flex-row-reverse items-center gap-1"
      title={props.isPinned ? "Role is pinned" : undefined}
    >
      {props.isPinned && <LockClosedIcon className="w-4" />}
      <TextProperty value={props.role} />
    </span>
  );
};

const useCell = (serialNumber?: string): Cell => {
  const placeData = usePlaceData();

  if (!serialNumber) {
    return placeholderCellData;
  }

  return placeData.cells[serialNumber] ?? placeholderCellData;
};

const CellPanel = () => {
  const { serialNumber } = useParams<{ serialNumber: string }>();
  const cell = useCell(serialNumber);

  return (
    <aside className="divide-y divide-stone-100 overflow-hidden bg-white dark:divide-neutral-600 dark:bg-neutral-700">
      <div className="px-3 py-3 sm:px-6">
        <Label size={ControlSize.Large} title={cell.serialNumber} />
      </div>
      <div
        className="bg-stone-50 px-4 py-2 dark:bg-neutral-800 sm:px-6"
        onClick={() => console.log("toggle advanced")}
      >
        <dl className="divide-y divide-stone-200 dark:divide-neutral-700">
          <Row title="Model" value={formatModel(cell.model)} />
          <Row title="Status" value={cell.isOnline ? "Online" : "Offline"} />
          <Row title="Last Seen" value={formatRelativeDate(cell.lastSeen)} />
          <Row title="OS Version" value={formatOSVersion(cell.syngOSVersion)} />
          <OTARow
            title="Software Update"
            value={cell.ota}
            isOnline={cell.isOnline}
          />
          <Row title="Role">
            <RoleIndicator role={cell.role} isPinned={cell.rolePinned} />
          </Row>
          <Row title="Network" value={cell.ssid} />
          <Row title="BSSID" value={cell.bssid} />
          <Row title="IP Address" value={cell.ipAddress} />
          <Row title="RSSI" value={formatRSSI(cell.rssi)} />
          <Row title="Wi-Fi Strength" value={formatWiFiStrength(cell.rssi)} />
          <Row title="Wi-Fi Band" value={formatWiFiChannel(cell.wifiChannel)} />
          <Row title="Calibrated" value={formatBoolean(cell.isCalibrated)} />
          <Row title="Sy-Fi Good" value={formatBoolean(cell.isSyFiGood)} />
          <Row title="Time Sync Good" value={formatBoolean(cell.isTimeSyncGood)} />
          <Row title="Height Layer" value={formatHeightLayer(cell.height_layer)} />
        </dl>
      </div>
    </aside>
  );
};

export default CellPanel;
export { type OTA, OTAIndicator };
